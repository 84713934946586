<template>
    <secondary-page-layout :title="$t('confirmation')">
        <otp-verify v-model="code" :info="info" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage" :bonus="bonus"></otp-verify>
    </secondary-page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OtpVerify from '../components/OTP/OtpVerify';
import ePayPayment from "../mixin/ePayPayment";
import eventLogger from "../services/eventLogger";

export default {
  name: "OgpoOtpVerification",
  mixins: [ePayPayment],
  computed: {
    ...mapGetters({
        OTPKEY: 'ogpoForm/otpKey',
        phoneNumber: 'ogpoForm/phoneNumber',
        invoiceId: 'ogpoForm/invoiceId',
        homebankToken: 'ogpoForm/homebankToken',
        email: 'ogpoForm/email',
        selectedProductId: "ogpoForm/selectedProductId",
        selectedProductName: "ogpoForm/selectedProductName",
        products: "ogpoForm/products",
        clients: "ogpoForm/clients",
        period: 'ogpoForm/period',
        beginDate: "ogpoForm/beginDate",
        calculatedSum: 'ogpoForm/calculatedSum',
        cascoSelected: 'ogpoForm/cascoSelected'
    }),
    info() {
        return { phone_number: this.phoneNumber }
    },
    fixSum() {
        const calculatedSum = this.$store.getters['ogpoForm/calculatedSum'];

        return calculatedSum?.fixedSum
    },
    bonus() {
      const { selectedProductId, products } = this;

      if(!selectedProductId || products.length === 0) { return }

      return products.find((b) => b.mergeProduct.id === selectedProductId)
    },
  },
  data(){
    return {
      errorMessage: null,
      code: '',
      calculationData: null
    }
  },
  components: {
    OtpVerify
  },
  methods: {
    ...mapActions({
        sendOTP: "ogpoForm/sendOTP",
        verifyOTP: 'ogpoForm/verifyOTP',
        saveDraft: 'ogpoForm/saveWithoutDebounce'
    }),
    async sendConfirmCode() {
      try {
        await this.sendOTP();

        this.showVerify = true;
      } catch (e) {
        throw e
      }
    },
    async verifyCode(code) {
      let loader = this.$loading.show();

      try {
        await this.verifyOTP(code)

        const { data } = await window.axios.post(`insurance-products/calculations/ogpo`, {
          invoiceId: this.invoiceId.toString(),
          amount: this.fixSum,
          holder: this.clients[0],
          phone_number: this.phoneNumber,
          beginDate: this.beginDate,
          calculation: this.$store.state['ogpoForm'].draftId
        })

        this.calculationData = data;

        this.saveDraft();
        await this.goToPaymentPage(this.calculationData, this.$store.getters['authUser'])

        // await this.ogpoPayment(data, this.invoiceId, this.fixSum, this.$store.getters['authUser'])
        // this.$refs.paymentForm.submit();
      } catch (err) {
        if (err.code === 410) {
            this.errorMessage = "Пользователь ввел неверный код подтверждения";
            return;
            this.errorMessage = this.$i18n.t("otpStep.errors.tryAmount");

        }
        if (err.code === 404) {
            this.errorMessage =  "Время действия кода подтверждения истекло";
            return;
            this.errorMessage = this.$i18n.t("otpStep.errors.timeGone");

        }
        if (err.code === 400) {
            this.errorMessage = "Пользователь ввел неверный код подтверждения";
            return;
            this.errorMessage = this.$i18n.t("otpStep.errors.invalidCode");

        }
        if (err.code === 403) {
            this.errorMessage = "Код более не валиден";
            return;
            this.errorMessage = this.$i18n.t("otpStep.errors.codeGone");
        }
        if (err.code === 500) {
            this.errorMessage = "Не удалось верифицировать номер телефона";
            return;
            this.errorMessage = this.$i18n.t("otpStep.errors.serviceError");
        }
      } finally {
        loader.hide()
      }
    },
  },
};
</script>
